import _ from 'lodash';
import axios from 'axios';
import {
  setBankInfoErrorMsg,
  setBankInfoLoading,
  setBankLinkFailure,
  setBankLinkSkipped,
  setBankList,
  setBankLoader,
  setBankResMsg,
  setLinkToken
} from '@app/src/actions/bankActions';
import { setBankLinks } from '@app/src/actions/pricingActions';
import { setSettingsModalType } from '@app/src/actions/settingsActions';
import { CAPITAL_ONE_LINK_EXPENSE_UPLOAD_MSG } from '@app/src/constants/bankLinkConstants';
import { Url_FACEBOOK_CLICK_ID_STORAGE_KEY } from '@app/src/constants/onboardingConstants';
import { serverUrl } from '@app/src/global/Environment';
import { isAppToAppRedirectBrowser } from '@app/src/global/Helpers';
import history from '@app/src/keeperHistory';
import { generateClientDedupId, getAnalyticsTrait, trackActivity } from '@app/src/services/analyticsService';
import { changeAccountType, relinkBank } from '@app/src/services/bankService';
import { notify } from '@app/src/utils/snackbarUtils';

const baseUrl = serverUrl();

export const initPlaid =
  ({ type }) =>
  async (dispatch, getState) => {
    if (type === 'relink') {
      const relinkToken = _.get(getState(), ['bank', 'bankInfo', 'public_token']);
      dispatch(setLinkToken(relinkToken));

      // the oauth return url cannot have a parameter, so save the relevant item id in storage
      const itemId = _.get(getState(), ['bank', 'bankInfo', 'item_id']);
      localStorage.setItem('keeper_item_id', itemId);
    } else {
      await dispatch(getLinkToken(type));
    }
    const linkToken = _.get(getState(), ['bank', 'linkToken']);
    trackActivity('storing new link token', {
      linkToken,
      tokenLength: linkToken?.length || 0,
      type
    });
    localStorage.setItem('keeper_link_token', linkToken);
  };

export const runOnEvent =
  ({ type, origin, eventName, metadata }) =>
  async (dispatch) => {
    if (eventName === 'OPEN') {
      trackActivity('bank link attempt', { origin });
      trackActivity('bank link: open plaid', { origin });
    } else if (eventName === 'HANDOFF') {
      // This is called after (1) the user successfully completes the oauth flow for their bank,
      // and (2) the user clicks the "Done" button in the Plaid window.
      trackActivity('bank link: handoff', { origin, type, eventName });

      // Get a new link token in case the user wants to link another bank
      await dispatch(initPlaid({ type }));
    } else if (eventName === 'SEARCH_INSTITUTION' && metadata.institution_search_query) {
      trackActivity('bank link: search institution', {
        search_text: metadata.institution_search_query,
        origin
      });

      if (_.toLower(_.trimStart(metadata.institution_search_query)) === 'apple' && origin === 'settings') {
        dispatch(setSettingsModalType('expense-upload-link-prompt'));
      }
    } else if (eventName === 'SELECT_INSTITUTION') {
      trackActivity('bank link: select institution', {
        institution_name: metadata.institution_name,
        origin
      });

      // Special case: if the user selects Chase within a third party app webview, skip the Plaid window
      // For banks with app-to-app redirect, if the user has the mobile app installed, the mobile app
      // (1) won't redirect back to the webview, and (2) won't pass the oauth_state_id back to the webview.
      if (isAppToAppRedirectBrowser() && metadata.institution_name === 'Chase') {
        trackActivity('bank link failed', {
          type: 'app-to-app redirect',
          institution_name: metadata.institution_name,
          origin
        });
        history.push('/onboarding/app-download');
      }
    } else if (eventName === 'FAIL_OAUTH' || (eventName === 'ERROR' && !_.isNil(metadata.institution_name))) {
      // OAuth failures sometimes return as server errors. if there's an institution
      // attached then it's probably an OAuth issue
      trackActivity('bank link: fail oauth', {
        institution_name: metadata.institution_name,
        origin,
        eventName
      });
    }
  };

const relinkSuccess = () => async (dispatch, getState) => {
  dispatch(setBankInfoLoading(true));
  const bankInfo = _.get(getState(), ['bank', 'bankInfo']);
  const bankObj = _.pick(bankInfo, ['access_token']);
  const res = await new Promise((resolve) => {
    relinkBank(bankObj, resolve);
  });
  const { status, msg } = res;
  if (status === 'ok') {
    trackActivity('bank account relinked (frontend)');
    history.push('/bank');
    dispatch(setBankInfoErrorMsg(null));
    dispatch(setBankInfoLoading(false));
  } else {
    dispatch(setBankInfoErrorMsg(msg));
    dispatch(setBankInfoLoading(false));
  }
};

const linkSuccess =
  ({ type, origin, publicToken, metadata }) =>
  async (dispatch, getState) => {
    try {
      trackActivity('bank link: link success', {
        origin,
        type,
        publicToken,
        institution_id: metadata.institution.institution_id,
        account_id: metadata.accounts[0].id
      });

      dispatch(setBankLoader(true));

      const fbc = getAnalyticsTrait(Url_FACEBOOK_CLICK_ID_STORAGE_KEY);
      const client_dedup_id = generateClientDedupId();

      if (origin !== 'webview') {
        const bankObj = {
          public_token: publicToken,
          account_id: metadata.accounts[0].id,
          account_type: metadata.accounts[0].type,
          account_subtype: metadata.accounts[0].subtype,
          institution_name: metadata.institution.name,
          institution_id: metadata.institution.institution_id,
          subacct_ids: JSON.stringify(metadata.accounts.map((data) => data.id)),
          ...(fbc && { fbc }),
          ...(client_dedup_id && { client_dedup_id })
        };

        if (origin === 'settings' && bankObj.institution_name === 'Capital One') {
          notify(CAPITAL_ONE_LINK_EXPENSE_UPLOAD_MSG);
        }

        const res = await axios.post(`${baseUrl}api/plaid/get-access-token`, bankObj);

        const { status, msg } = res.data;
        const userId = _.get(getState(), ['auth', 'user', 'id']);

        if (status === 'ok') {
          trackActivity('bank account linked (frontend)', {
            institution_name: bankObj.institution_name,
            userId,
            ...(client_dedup_id && { client_dedup_id }),
            origin
          });

          if (type === 'payment' || type === 'payment_native') {
            for (const account of metadata.accounts) {
              dispatch(
                changeAccountType(
                  {
                    account_id: account.id,
                    expense_type: 'personal only'
                  },
                  () => {}
                )
              );
            }
          }
        } else {
          throw new Error(msg);
        }
      }

      const bankData = await axios.get(`${baseUrl}api/profile/banklinks-new`);

      const bankLinks = _.get(bankData, ['data', 'data']);

      dispatch(setBankList(bankLinks));
      dispatch(setBankLinks({ bankLinks }));
      dispatch(setBankLinkFailure(false));
      dispatch(setBankResMsg({ error: '' }));
    } catch ({ message }) {
      dispatch(setBankLinkFailure(true));
      dispatch(setBankResMsg({ error: message }));
    } finally {
      dispatch(setBankLoader(false));
    }
  };

export const runOnSuccess =
  ({ type, origin, publicToken, metadata }) =>
  async (dispatch) => {
    if (type === 'relink') {
      await dispatch(relinkSuccess());
    } else {
      await dispatch(linkSuccess({ type, origin, publicToken, metadata }));
    }
  };

export const runOnExit =
  ({ type, origin, error, metadata }) =>
  async (dispatch) => {
    trackActivity('bank link: close plaid window', {
      error_type: _.get(error, 'error_type'),
      error_code: _.get(error, 'error_code'),
      error_message: _.get(error, 'error_message'),
      request_id: _.get(metadata, 'request_id'),
      institution_id: _.get(metadata, ['institution', 'institution_id']),
      institution_name: _.get(metadata, ['institution', 'name']),
      origin
    });
    if (!_.isNil(error)) {
      trackActivity('bank link failed', {
        error_type: _.get(error, 'error_type'),
        error_code: _.get(error, 'error_code'),
        error_message: _.get(error, 'error_message'),
        request_id: _.get(metadata, 'request_id'),
        institution_id: _.get(metadata, ['institution', 'institution_id']),
        institution_name: _.get(metadata, ['institution', 'name']),
        origin
      });

      dispatch(setBankLinkFailure(true));

      if (error.error_code === 'INVALID_LINK_TOKEN') {
        await dispatch(initPlaid({ type }));
      }
    } else {
      dispatch(setBankLinkSkipped(true));
      trackActivity('bank link failed', {
        type: 'cancel',
        institution_name: _.get(metadata, ['institution', 'name']),
        status: _.get(metadata, 'status'),
        origin
      });
    }
  };

const getLinkToken = (type) => async (dispatch) => {
  const res = await axios.get(`${baseUrl}api/plaid/create-link-token`, {
    params: {
      type
    }
  });
  const linkToken = _.get(res, ['data', 'data', 'token', 'link_token']);
  dispatch(setLinkToken(linkToken));
};

export const getCapitalOneAccessToken =
  ({ item_id, code }) =>
  async () => {
    await axios.post(`${baseUrl}api/plaid/get-capital-one-access-token`, {
      item_id,
      code
    });
  };

export const parseLinkToken = () => (dispatch) => {
  const linkToken = localStorage.getItem('keeper_link_token');
  trackActivity('bank link: parse link token', {
    linkToken,
    tokenLength: linkToken.length
  });
  if (!linkToken) {
    history.push('/');
    return;
  }
  dispatch(setLinkToken(linkToken));
};
