import React, { useCallback, useEffect, useState } from 'react';
import _ from 'lodash';
import Button from '@mui/material/Button';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { usePrevious } from 'react-use';
import TaxFlowPillsSection from '@app/src/Components/TaxFlow/Common/TaxFlowPillsSection';
import TaxFlowFormUploadItem from '@app/src/Components/TaxFlow/Question/TaxFlowFormUploadItem';
import { setOrigin, setOverrides, setPlansShown } from '@app/src/actions/pricingActions';
import { setSubscriptionModalShow } from '@app/src/actions/taxFlowActions';
import { FIXED_CACHE_KEYS } from '@app/src/api/baseApi';
import { useGetFeaturesQuery } from '@app/src/api/profileApi';
import {
  useGenerateQuestionnaireMutation,
  useGetBulkUploadPillsQuery,
  useGetBulkUploadSuggestionPillsQuery,
  useGetQuestionnaireProgressQuery,
  useGetTaxDataQuery,
  useUpdateTaxDataMutation
} from '@app/src/api/taxDataApi';
import MagicStar from '@app/src/assets/magic-star.svg?react';
import { SUBSCRIPTION_MODAL_TYPES, SUBSCRIPTION_PLANS, SUBSCRIPTION_STATUSES } from '@app/src/constants/constants';
import { PRICING_ORIGINS } from '@app/src/constants/pricingConstants';
import { isReactNative, sentMsgToReactNative } from '@app/src/global/Helpers';
import { useIsMobile } from '@app/src/hooks/useIsMobile';
import { useShowPremiumBlockingModal } from '@app/src/hooks/useShowPremiumBlockingModal';
import { TAX_YEAR_LIFECYCLE_STAGES, useTaxYearLifecycle } from '@app/src/hooks/useTaxYearLifecycle';
import { subscriptionInfoSelector } from '@app/src/selectors/pricingSelectors';
import { trackActivity } from '@app/src/services/analyticsService';
import { setCurrentAnswer } from '@app/src/services/taxFlowAnswerService';
import { getUploadAttemptsStatuses } from '@app/src/taxFlowMessageEntrypoint';
import TaxFlowBulkUploadModal from '@app/src/taxflow/collection/components/TaxFlowBulkUploadModal';
import TaxFlowCollectionDeleteModal from '@app/src/taxflow/collection/components/TaxFlowCollectionDeleteModal';
import { getBulkUploadFormUrl } from '@app/src/taxflow/collection/utils/collectionUtils';
import { bulkUploadItemsSelector } from '@app/src/taxflow/main/selectors/formUploadSelectors';
import { yearSelector } from '@app/src/taxflow/main/selectors/mainSelectors';
import {
  COLLECTION_TYPE__SPECIAL,
  ENDPOINT_ATTRIBUTE__ALL_FORMS_UPLOADED
} from '@app/src/taxflow/sections/special/constants/specialConstants';
import { ENDPOINT_ATTRIBUTE__SUBMIT_SUBSCRIPTION_PROMPT } from '@app/src/taxflow/sections/submit/constants/submitConstants';
import { DEFAULT_COLLECTION_ID } from '@app/src/taxflow/shared/constants/sharedConstants';
import { isWebviewFocusedSelector } from '@app/src/taxflow/shared/selectors/sharedSelectors';

const MAX_BULK_SUGGESTIONS_MOBILE = 4;
const MAX_BULK_SUGGESTIONS_DESKTOP = 8;

const TaxFlowBulkUploadItem = ({ getUploadAttemptsStatuses, setLoading }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isMobile = useIsMobile();
  const year = useSelector(yearSelector);
  const showPremiumBlockingModal = useShowPremiumBlockingModal();
  const subscriptionInfo = useSelector(subscriptionInfoSelector);
  const isWebviewFocused = useSelector(isWebviewFocusedSelector);

  const { data: taxData, isLoading: taxDataLoading } = useGetTaxDataQuery({
    year
  });
  const { data: bulkUploadPills, isLoading: bulkUploadPillsLoading } = useGetBulkUploadPillsQuery({ year });
  const { data: questionnaireProgress, isLoading: isQuestionnaireProgressLoading } = useGetQuestionnaireProgressQuery({
    year
  });
  const questionnaireComplete = !!questionnaireProgress?.questionnaireComplete;
  const { data: bulkUploadSuggestionPills, isLoading: bulkUploadSuggestionPillsLoading } =
    useGetBulkUploadSuggestionPillsQuery({ year }, { skip: questionnaireComplete });
  const { data: features, isLoading: featuresLoading } = useGetFeaturesQuery();
  const { stage: taxLifecycleStage, isLoading: isTaxYearLifecycleLoading } = useTaxYearLifecycle();
  const [generateQuestionnaire] = useGenerateQuestionnaireMutation({
    fixedCacheKey: FIXED_CACHE_KEYS.GENERATE_QUESTIONNAIRE
  });

  const previousBulkUploadPills = usePrevious(bulkUploadPills);
  const [pills, setPills] = useState([]);
  const [showCollectionDeleteModal, setShowCollectionDeleteModal] = useState(false);
  const [collectionDeleteModalTarget, setCollectionDeleteModalTarget] = useState({
    collectionType: undefined,
    collectionId: undefined
  });

  const isLoading =
    taxDataLoading ||
    bulkUploadPillsLoading ||
    bulkUploadSuggestionPillsLoading ||
    isQuestionnaireProgressLoading ||
    featuresLoading ||
    isTaxYearLifecycleLoading;

  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading, setLoading]);

  useEffect(() => {
    // Show paywall if user is in the PRE_FREE_TRIAL state and has not already seen the prompt
    const hasAlreadySeenPrompt =
      !taxDataLoading && _.some(taxData, { slug: ENDPOINT_ATTRIBUTE__SUBMIT_SUBSCRIPTION_PROMPT });
    if (
      pills.length > 0 &&
      _.get(subscriptionInfo, 'subscription.status') === SUBSCRIPTION_STATUSES.PRE_FREE_TRIAL &&
      !hasAlreadySeenPrompt &&
      _.includes(
        [TAX_YEAR_LIFECYCLE_STAGES.FULL, TAX_YEAR_LIFECYCLE_STAGES.FULL_EXCEPT_SIGN_AND_FILE],
        taxLifecycleStage
      )
    ) {
      if (!isReactNative()) {
        dispatch(setOrigin({ origin: PRICING_ORIGINS.TAX_FILING_TRIAL_PAYWALL }));
        dispatch(
          setOverrides({
            subscriptionBenefits: {
              title: 'Try Keeper free for 7 days',
              CTA: {
                primary: {
                  text: 'Continue',
                  nextScreen: SUBSCRIPTION_MODAL_TYPES.SUBSCRIPTION_TYPE,
                  plans: [SUBSCRIPTION_PLANS.PREMIUM, SUBSCRIPTION_PLANS.ANNUAL, SUBSCRIPTION_PLANS.FILING_ONLY]
                },
                tertiary: {
                  text: 'Skip for now'
                }
              },
              body: { image: 'timeline', height: 410 },
              timeline: {
                stepOne: {
                  text: 'Get started on your tax return by dropping off your forms and claiming credits!'
                },
                stepThree: {
                  title: 'Pay after trial or when you file',
                  text: 'Start your annual plan to file with Keeper or easily export your deductions!'
                }
              }
            }
          })
        );
        dispatch(
          setPlansShown({
            plansShown: [SUBSCRIPTION_PLANS.PREMIUM, SUBSCRIPTION_PLANS.ANNUAL, SUBSCRIPTION_PLANS.FILING_ONLY]
          })
        );
        dispatch(setSubscriptionModalShow(true));
      } else if (isWebviewFocused) {
        sentMsgToReactNative('open_subscribe_modal', { origin: PRICING_ORIGINS.TAX_FILING_TRIAL_PAYWALL });
      }

      return;
    }
  }, [dispatch, subscriptionInfo, taxData, taxDataLoading, pills, isWebviewFocused, taxLifecycleStage]);

  const navigateToLinkedQuestion = useCallback(
    ({ collectionType, collectionId, clickable, nextSlug, text, isPremium }) => {
      trackActivity('bulk upload: pill clicked', { collectionType, collectionId, pillName: text });
      if (isPremium) {
        showPremiumBlockingModal({ formName: text });
      } else if (clickable && !_.isNil(nextSlug)) {
        history.push(getBulkUploadFormUrl({ nextPathComponent: nextSlug, collectionId }));
      }
    },
    [history, showPremiumBlockingModal]
  );
  const confirmPillDeletion = useCallback(({ collectionType, collectionId, text }) => {
    trackActivity('bulk upload: pill removal requested', { collectionType, collectionId, pillName: text });
    setShowCollectionDeleteModal(true);
    setCollectionDeleteModalTarget({
      collectionType: collectionType,
      collectionId
    });
  }, []);

  const onPillRemoved = () => {
    getUploadAttemptsStatuses();
    if (!questionnaireComplete) {
      generateQuestionnaire({ year });
    }
  };

  const parseInProgress = _.some(bulkUploadPills, { loading: true });
  const priorParseInProgress = _.some(previousBulkUploadPills, { loading: true });

  // Re-generate questionnaire upon parse completion (we may need to ask a different set of questions)
  useEffect(() => {
    if (!questionnaireComplete && !parseInProgress && priorParseInProgress) {
      generateQuestionnaire({ year });
    }
  }, [
    parseInProgress,
    priorParseInProgress,
    questionnaireProgress,
    questionnaireComplete,
    year,
    generateQuestionnaire
  ]);

  // Form upload UI analytics - report parse behavior as seen by the user
  useEffect(() => {
    if (_.isNil(previousBulkUploadPills)) {
      return;
    }
    const pillsWithLoadingChange = _.differenceWith(bulkUploadPills, previousBulkUploadPills, (pill, previousPill) =>
      _.isMatch(pill, _.pick(previousPill, ['collectionType', 'collectionId', 'loading']))
    );

    _.map(pillsWithLoadingChange, ({ collectionType, collectionId, loading, parseFailed, validationFailed }) => {
      if (loading) {
        trackActivity('bulk upload FE: parsing waiting', { collectionType, collectionId });
      } else {
        trackActivity('bulk upload FE: parsing complete', {
          success: !parseFailed && !validationFailed,
          uploadStatus: parseFailed ? 'failure' : 'success',
          collectionType,
          collectionId
        });
      }
    });
  }, [bulkUploadPills, previousBulkUploadPills]);

  useEffect(() => {
    setPills(
      _.map(
        bulkUploadPills,
        ({
          collectionType,
          collectionId,
          loading,
          text,
          validationFailed,
          parseFailed,
          clickable,
          formInputQuestionSlug,
          isPremium
        }) => ({
          text,
          variant: validationFailed || parseFailed ? 'warning' : 'default',
          icon: isPremium && <MagicStar />,
          loading,
          removable: true,
          onClick: () =>
            navigateToLinkedQuestion({
              collectionType,
              collectionId,
              clickable,
              nextSlug: formInputQuestionSlug,
              text,
              isPremium
            }),
          onRemove: () => confirmPillDeletion({ collectionType, collectionId, text })
        })
      )
    );
  }, [bulkUploadPills, setPills, navigateToLinkedQuestion, confirmPillDeletion]);

  if (isLoading) {
    return null;
  }

  return (
    <>
      <TaxFlowFormUploadItem
        isBulkUpload={true}
        bulkUploadSuggestionPills={
          !questionnaireComplete &&
          _.take(bulkUploadSuggestionPills, isMobile ? MAX_BULK_SUGGESTIONS_MOBILE : MAX_BULK_SUGGESTIONS_DESKTOP)
        }
      ></TaxFlowFormUploadItem>
      {!_.isEmpty(bulkUploadPills) && (
        <TaxFlowPillsSection sectionHeader={'Your forms'} pills={pills}></TaxFlowPillsSection>
      )}
      {/* Don't allow users to mark all forms as uploaded when early in the tax season and only bulk upload is open (ie users can't progress to questionnaire) */}
      {taxLifecycleStage !== TAX_YEAR_LIFECYCLE_STAGES.BULK_ONLY && (
        <AllFormsAddedButton
          bulkUploadPills={bulkUploadPills}
          taxData={taxData}
          generateQuestionnaire={generateQuestionnaire}
          features={features}
        />
      )}
      {showCollectionDeleteModal && (
        <TaxFlowCollectionDeleteModal
          collectionType={collectionDeleteModalTarget.collectionType}
          collectionId={collectionDeleteModalTarget.collectionId}
          hideModal={() => setShowCollectionDeleteModal(false)}
          onDelete={onPillRemoved}
        />
      )}
      <TaxFlowBulkUploadModal />
    </>
  );
};

const AllFormsAddedButton = ({ bulkUploadPills, taxData, generateQuestionnaire, features }) => {
  const year = useSelector(yearSelector);
  const [updateTaxData] = useUpdateTaxDataMutation();

  const bulkOptional = _.some(features, { name: 'bulk-optional', value: 1 });
  const userNeedsToAddForms = _.isEmpty(bulkUploadPills) && !bulkOptional;

  const markAllFormsUploaded = () => {
    updateTaxData({
      taxData: [
        {
          coll_type: COLLECTION_TYPE__SPECIAL,
          coll_id: DEFAULT_COLLECTION_ID,
          slug: ENDPOINT_ATTRIBUTE__ALL_FORMS_UPLOADED,
          value: '1'
        },
        {
          coll_type: COLLECTION_TYPE__SPECIAL,
          coll_id: DEFAULT_COLLECTION_ID,
          slug: 'special-started',
          value: '1'
        }
      ],
      generateSharedCollectionId: false,
      year
    });
    generateQuestionnaire({ year });
  };

  const userClaimsAllFormsUploaded = _.some(taxData, {
    slug: ENDPOINT_ATTRIBUTE__ALL_FORMS_UPLOADED,
    value: '1'
  });

  if (userClaimsAllFormsUploaded) {
    return null;
  }

  return (
    <Button
      fullWidth
      size='large'
      variant='contained'
      disabled={userNeedsToAddForms || _.some(bulkUploadPills, { loading: true })}
      onClick={markAllFormsUploaded}
    >
      <div>{userNeedsToAddForms ? `Add tax forms to continue` : `I've uploaded all my forms`}</div>
    </Button>
  );
};

const mapStateToProps = (state) => ({
  collectionDeleteModalShow: _.get(state, ['taxFlowModals', 'collectionDeleteModalShow']),
  bulkUploadItems: bulkUploadItemsSelector(state)
});

const mapDispatchToProps = {
  setCurrentAnswer,
  getUploadAttemptsStatuses
};

const ConnectedTaxFlowBulkUploadItem = connect(mapStateToProps, mapDispatchToProps)(TaxFlowBulkUploadItem);

export default ConnectedTaxFlowBulkUploadItem;
