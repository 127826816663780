import _ from 'lodash';
import { PATH_COMPONENT__TAX_HOME } from '@app/src/taxflow/sections/special/constants/specialConstants';
import {
  PATH_COMPONENT__SUBMIT_DEBIT,
  SLUG__SUBMIT_DEBIT,
  SLUG__SUBMIT_DEBIT_MANUAL,
  PATH_COMPONENT__SUBMIT_DEBIT_MANUAL,
  PATH_COMPONENT__SUBMIT_PIN,
  SLUG__SUBMIT_PIN,
  PATH_COMPONENT__SUBMIT_SIGNATURE,
  SLUG__SUBMIT_SIGNATURE,
  PATH_COMPONENT__SUBMIT_EMAIL_INFO,
  SLUG__SUBMIT_EMAIL_INFO,
  PATH_COMPONENT__SUBMIT_CONFIRMATION,
  SLUG__SUBMIT_CONFIRMATION,
  PATH_COMPONENT__SUBMIT_BANK_NUMBERS,
  SLUG__SUBMIT_BANK_NUMBERS,
  PATH_COMPONENT__SUBMIT_CONFIRM_ID,
  SLUG__SUBMIT_CONFIRM_ID,
  PATH_COMPONENT__SUBMIT_CONFIRM_ID_INTRO,
  SLUG__SUBMIT_CONFIRM_ID_INTRO,
  COLLECTION_TYPE__SUBMIT_DEBIT,
  COLLECTION_TYPE__SUBMIT_EMAIL_INFO,
  COLLECTION_TYPE__SUBMIT_SIGNATURE,
  ENDPOINT_ATTRIBUTE__SUBMIT_SIGNATURE_SELF,
  ENDPOINT_ATTRIBUTE__SUBMIT_SIGNATURE_SPOUSE,
  ENDPOINT_ATTRIBUTE__SUBMIT_EMAIL_INFO,
  ENDPOINT_ATTRIBUTE__SUBMIT_DEBIT_PAYMENT_METHOD,
  ENDPOINT_ATTRIBUTE__SUBMIT_DEBIT_MANUAL_OPTION,
  ENDPOINT_ATTRIBUTE__SUBMIT_DEBIT_ACCOUNT,
  ENDPOINT_ATTRIBUTE__SUBMIT_DEBIT_ACCOUNT_ID
} from '@app/src/taxflow/sections/submit/constants/submitConstants';
import { getFilingStatus } from '@app/src/taxflow/shared/utils/sharedUtils';

export const getNextPathComponentMap = () => {
  return {
    [SLUG__SUBMIT_EMAIL_INFO]: {
      nextPathComponent: PATH_COMPONENT__SUBMIT_SIGNATURE,
      questionnaireEarlyExit: true
    },
    [SLUG__SUBMIT_CONFIRMATION]: {
      nextPathComponent: PATH_COMPONENT__SUBMIT_CONFIRMATION,
      questionnaireEarlyExit: true
    },
    [SLUG__SUBMIT_CONFIRM_ID_INTRO]: {
      nextPathComponent: PATH_COMPONENT__SUBMIT_CONFIRM_ID,
      questionnaireEarlyExit: true
    },
    [SLUG__SUBMIT_BANK_NUMBERS]: {
      nextPathComponent: PATH_COMPONENT__SUBMIT_EMAIL_INFO,
      questionnaireEarlyExit: true
    },
    [SLUG__SUBMIT_CONFIRM_ID]: {
      nextPathComponent: PATH_COMPONENT__TAX_HOME,
      questionnaireEarlyExit: true
    }
  };
};

export const getNextPathComponent = ({ question, answer, irsPaymentAccount }) => {
  let nextPathComponent = null;

  if (question.slug === SLUG__SUBMIT_DEBIT || question.slug === SLUG__SUBMIT_DEBIT_MANUAL) {
    const chaseAccountSelected = _.get(irsPaymentAccount, 'institution_id') === 'ins_56';

    if (_.get(answer, 'value') === 'ach' && chaseAccountSelected) {
      nextPathComponent = PATH_COMPONENT__SUBMIT_BANK_NUMBERS;
    } else {
      nextPathComponent = PATH_COMPONENT__SUBMIT_EMAIL_INFO;
    }
  } else if (question.slug === SLUG__SUBMIT_SIGNATURE) {
    nextPathComponent = PATH_COMPONENT__SUBMIT_CONFIRMATION;
  }
  return { nextPathComponent };
};

export const getSlugMap = () => {
  return {
    [PATH_COMPONENT__SUBMIT_PIN]: SLUG__SUBMIT_PIN,
    [PATH_COMPONENT__SUBMIT_DEBIT]: SLUG__SUBMIT_DEBIT,
    [PATH_COMPONENT__SUBMIT_DEBIT_MANUAL]: SLUG__SUBMIT_DEBIT_MANUAL,
    [PATH_COMPONENT__SUBMIT_BANK_NUMBERS]: SLUG__SUBMIT_BANK_NUMBERS,
    [PATH_COMPONENT__SUBMIT_EMAIL_INFO]: SLUG__SUBMIT_EMAIL_INFO,
    [PATH_COMPONENT__SUBMIT_SIGNATURE]: SLUG__SUBMIT_SIGNATURE,
    [PATH_COMPONENT__SUBMIT_CONFIRMATION]: SLUG__SUBMIT_CONFIRMATION,
    [PATH_COMPONENT__SUBMIT_CONFIRM_ID_INTRO]: SLUG__SUBMIT_CONFIRM_ID_INTRO,
    [PATH_COMPONENT__SUBMIT_CONFIRM_ID]: SLUG__SUBMIT_CONFIRM_ID
  };
};

/** Gets whether the submit-signature question has been answered based on the user's filing status and taxflow data. */
const getSignatureQuestionAnswered = ({ answers, queryResults }) => {
  const filingStatus = getFilingStatus({ queryResults });
  const signedForSelf = !_.isEmpty(answers[ENDPOINT_ATTRIBUTE__SUBMIT_SIGNATURE_SELF]);
  const signedForSpouse = !_.isEmpty(answers[ENDPOINT_ATTRIBUTE__SUBMIT_SIGNATURE_SPOUSE]);
  const signatureQuestionAnswered = filingStatus === 'married' ? signedForSelf && signedForSpouse : signedForSelf;
  return signatureQuestionAnswered;
};

/** Gets the linked account to be used to pay bill/get refund. */
const getLinkedAccount = ({ answers, chargeableAccounts }) => {
  const linkedAccountId = answers[ENDPOINT_ATTRIBUTE__SUBMIT_DEBIT_ACCOUNT_ID];
  const account = chargeableAccounts.find(({ account_id }) => account_id === linkedAccountId);
  return account;
};

/** Gets whether the user's linked Chase account has no account number. */
const getIsMissingChaseAccountNumber = ({ answers, linkedAccount }) => {
  const isChaseAccount = _.get(linkedAccount, 'institution_id') === 'ins_56';
  const isMissingAccountNumber = _.isEmpty(answers[ENDPOINT_ATTRIBUTE__SUBMIT_DEBIT_ACCOUNT]);
  return isChaseAccount && isMissingAccountNumber;
};

/** Gets the appropriate path component for the current confirm amounts page. */
export const getConfirmAmountsPage = ({ taxData, queryResults, taxAmounts, chargeableAccounts, submitIssues }) => {
  if (!_.isEmpty(submitIssues)) {
    return PATH_COMPONENT__SUBMIT_DEBIT;
  }

  const answers = _.chain(taxData)
    .filter((item) =>
      [COLLECTION_TYPE__SUBMIT_DEBIT, COLLECTION_TYPE__SUBMIT_EMAIL_INFO, COLLECTION_TYPE__SUBMIT_SIGNATURE].includes(
        item.coll_type
      )
    )
    .groupBy('slug')
    .mapValues((group) => _.get(_.find(group, 'value'), 'value'))
    .value();

  if (getSignatureQuestionAnswered({ answers, queryResults })) {
    return PATH_COMPONENT__SUBMIT_CONFIRMATION;
  }

  const hasAnsweredEmailInfoQuestion = !_.isEmpty(answers[ENDPOINT_ATTRIBUTE__SUBMIT_EMAIL_INFO]);
  if (hasAnsweredEmailInfoQuestion) {
    return PATH_COMPONENT__SUBMIT_SIGNATURE;
  }

  const isUsingPaymentPlan = answers[ENDPOINT_ATTRIBUTE__SUBMIT_DEBIT_PAYMENT_METHOD] === 'paymentPlan';
  if (isUsingPaymentPlan) {
    return PATH_COMPONENT__SUBMIT_EMAIL_INFO;
  }

  const isAddingAccount = answers[ENDPOINT_ATTRIBUTE__SUBMIT_DEBIT_PAYMENT_METHOD] === 'addAccount';
  if (isAddingAccount) {
    const isManuallyAddedAccount =
      answers[ENDPOINT_ATTRIBUTE__SUBMIT_DEBIT_MANUAL_OPTION] === 'manual' &&
      !_.isEmpty(answers[ENDPOINT_ATTRIBUTE__SUBMIT_DEBIT_ACCOUNT]);
    if (isManuallyAddedAccount) {
      return PATH_COMPONENT__SUBMIT_EMAIL_INFO;
    }
    return PATH_COMPONENT__SUBMIT_DEBIT_MANUAL;
  }

  const linkedAccount = getLinkedAccount({ answers, chargeableAccounts });
  if (linkedAccount) {
    if (getIsMissingChaseAccountNumber({ answers, linkedAccount })) {
      return PATH_COMPONENT__SUBMIT_DEBIT;
    }
    return PATH_COMPONENT__SUBMIT_EMAIL_INFO;
  }

  const allMultistateZero = taxAmounts?.multistate?.every(({ amount }) => amount === 0) ?? true;
  const zeroTaxOwed = Number(taxAmounts?.federal_amount) === 0 && allMultistateZero;
  if (zeroTaxOwed) {
    return PATH_COMPONENT__SUBMIT_EMAIL_INFO;
  }

  return PATH_COMPONENT__SUBMIT_DEBIT;
};
