import _ from 'lodash';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import pRetry from 'p-retry';
import { serverUrl } from '@app/src/global/Environment';
import history from '@app/src/keeperHistory';
import { logout } from '@app/src/services/authService';
import defaultCaptureMessage from '@app/src/utils/sentry/defaultCaptureMessage';

const baseUrl = serverUrl();

export const TAGS = {
  // tax data
  ALL_EXPENSES: 'AllExpenses',
  BULK_UPLOAD_ALERT: 'BulkUploadAlert',
  CURRENT_QUESTIONNAIRE_QUESTION: 'CurrentQuestionnaireQuestion',
  ID_VERIFICATION_RESULT: 'IdVerificationResult',
  ID_VERIFICATION_QUESTIONS: 'IdVerificationQuestions',
  RETURN_STATUS: 'ReturnStatus',
  QUESTIONNAIRE_PROGRESS: 'QuestionnaireProgress',
  QUESTIONNAIRE_SUMMARY_PILLS: 'QuestionnaireSummaryPills',
  SSN_MATCHED: 'SsnMatched',
  SUBMIT_ISSUES: 'SubmitIssues',
  SUBMIT_TIMESTAMP: 'SubmitTimestamp',
  SUBMIT_WARNINGS: 'SubmitWarnings',
  TAX_AMOUNTS: 'TaxAmounts',
  TAX_INFO: 'TaxInfo',
  UI_STAGE: 'UIStage',

  // profile
  ACCOUNT_DETAILS: 'AccountDetails',
  CAMPAIGN: 'Campaign',
  FEATURES: 'Features',
  JOBS: 'Jobs',
  RETRO_STATUS: 'RetroStatus',
  TAX_ESTIMATE: 'TaxEstimate',
  TAX_PROFILE: 'TaxProfile',
  WORK_INFO: 'WorkInfo',
  APP_YEARS: 'AppYears',

  // plaid
  PAID: 'Paid',

  // expenses
  EXPENSE_REVIEW_DETAILS: 'ExpenseReviewDetails',

  // pricing
  SUBSCRIPTION_INFO: 'SubscriptionInfo'
};

export const FIXED_CACHE_KEYS = {
  GENERATE_QUESTIONNAIRE: 'generate-questionnaire'
};

const QUERY_RETRY_COUNT = 3;

const baseQuery = async (args, api, extraOptions) => {
  const result = await pRetry(
    async (attemptCount) => {
      const queryResult = await fetchBaseQuery({
        baseUrl: `${baseUrl}api/`,
        prepareHeaders: (headers) => headers.set('Authorization', localStorage.getItem('KeeperToken'))
      })(args, api, extraOptions);

      // Logout on unauthorized - the users token is not valid
      if (queryResult?.data?.msg === 'Unauthorized Request') {
        await api.dispatch(logout());
        history.push('/');
      } else if (!_.isNil(queryResult.error)) {
        defaultCaptureMessage(
          'query error',
          { ...queryResult.error, ...args, attemptCount },
          attemptCount === QUERY_RETRY_COUNT + 1 ? 'error' : 'warning'
        );
        throw Error('query error');
      }

      if (attemptCount > 1) {
        defaultCaptureMessage('query error resolved', { ...args, attemptCount }, 'log');
      }

      return queryResult;
    },
    { retries: QUERY_RETRY_COUNT }
  );
  return result;
};

export const baseApi = createApi({
  refetchOnReconnect: true,
  refetchOnFocus: true,
  // Keep unused query data for 3 mins (this is how long cached query state is valid after removal of all subscriptions)
  keepUnusedDataFor: 3 * 60,
  baseQuery,
  tagTypes: _.values(TAGS),
  endpoints: () => ({})
});
